<template>
  <div class="md-layout md-alignment-center">
    <div class="md-layout-item md-small-hide">
      <img src="@/assets/img/slide.jpg" alt="Logo" class="slide-image" />
    </div>
    <div
      class="
        md-layout-item
        md-size-50
        md-medium-size-50
        md-small-size-70
        md-xsmall-size-100
      "
    >
      <ValidationListener
        @getIsValid="(isValid) => (passwordReset.isFormValid = isValid)"
      >
        <login-card>
          <h3 slot="title" class="title"><br />Password Reset</h3>

          <p slot="subheader" class="md-caption text-center m-0">
            Forgot your password? Don't worry.
          </p>
          <p slot="subheader" class="md-caption text-center m-0">
            We'll send you an email to reset your password.
          </p>

          <p slot="description" class="description" />
          <div slot="inputs">
            <ValidationHandler
              :name="passwordReset.postData.email"
              rules="required"
              v-slot="{ error }"
            >
              <text-input
                type="email"
                label="Email..."
                v-model="passwordReset.postData.email"
                placeholder="Enter Email"
                :error="error"
                icon="mail_outline"
                :required="false"
                @submit="validateMember"
              />
            </ValidationHandler>
            <ValidationHandler
              :name="passwordReset.postData.confirmEmail"
              rules="required"
              v-slot="{ error }"
            >
              <text-input
                type="email"
                label="Email..."
                v-model="passwordReset.postData.confirmEmail"
                placeholder="Confirm Email"
                :error="error"
                icon="mail_outline"
                :required="false"
                @submit="validateMember"
              />
            </ValidationHandler>
          </div>
          <md-button slot="footer" @click="cancelReset()">Cancel</md-button>
          <spinner
            slot="footer"
            style="margin-bottom: 10px"
            :diameter="25"
            v-if="passwordReset.authenticating"
          ></spinner>
          <md-button
            v-else
            slot="footer"
            class="md-primary"
            @click="validateMember"
          >
            Submit
          </md-button>
        </login-card>
      </ValidationListener>
    </div>
  </div>
</template>

<script>
import LoginCard from "../../components/Cards/LoginCard.vue";
export default {
  components: {
    "login-card": LoginCard,
  },
  inject: ["passwordReset", "validateMember", "cancelReset"],
};
</script>

<style></style>
